.dialogContent {
    display: flex;
    gap: 30px;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
}
.dialogText{
    width: 200px;
}
.MuiDialog-paper {
    max-width: 80% !important;
}
.contentBox{
    display: flex;
    gap: 20px;
}