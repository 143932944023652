.detailsWrapper{
  width: 400px;
  border-left: 1px solid #aeaeae;
  overflow-y: scroll;
}
.detailsInnerWrap {
  padding: 10px;
}
.detailsHead{
  text-align: center;
  background: #acacac;
  padding: 20px;
  font-weight: 600;
}
.detailsSubhead {
  color: #1976d2;
  margin: 15px 0 10px;
  font-weight: 500;
}
.detailsBox {
  border: 1px solid #aeaeae;
  padding: 10px;
  background: #ccdded;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.detailsTitle {
  font-weight: 500;
  line-height: 25px;
}
.detailsText{
  color: #3f3737;
}
.imgWrapper {
  width: 300px;
}
.userImgWrapper {
  width: 300px;
  height: auto;
}
.titleWrapper{
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 10px 0;
}