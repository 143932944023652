.matchWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}
.matchImgWrapper{
  display: flex;
  gap:40px;
  padding-bottom: 60px;
  border-bottom: 1px solid #aeaeae;
}