.buttonWrapper {
  display: flex;
  gap: 15px;
  margin: 20px;
}
.allBtns {
  width: 150px;
  font-weight: 600 !important;
  cursor: pointer;
  /* background: #cca0216b;
  border: 1px solid #acacac; */

}